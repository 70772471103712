export default function Tilde(props) {
    return (
        <div style={{ mixBlendMode: "difference" }}>
            <svg
                width="27.86"
                height="25"
                viewBox="0 0 331 298"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M260.633 120.734C256.252 142.476 249.682 153.299 240.921 153.299C234.584 153.299 229.087 149.354 224.473 141.37C219.859 133.432 213.661 120.734 205.88 103.37C196.606 82.3494 188.078 65.1781 180.297 51.7571C172.516 38.3855 162.543 26.6002 150.381 16.4507C138.171 6.35064 123.585 1.25195 106.531 1.25195C66.1304 1.25195 37.5648 28.9573 20.7431 84.3697C8.56966 124.543 1.84252 172.879 0.554243 229.28C0.43324 234.578 0.372738 237.227 1.39875 239.323C2.29091 241.145 3.79168 242.68 5.59324 243.614C7.66509 244.688 10.3571 244.688 15.7412 244.688H49.9832C55.1593 244.688 57.7474 244.688 59.7252 243.729C61.4991 242.87 62.8933 241.565 63.8691 239.853C64.9571 237.943 65.1344 235.286 65.489 229.972C66.8596 209.43 69.3476 192.208 72.9797 178.31C78.8046 155.847 86.5875 144.64 96.3253 144.64C103.13 144.64 109.001 148.632 113.848 156.569C118.693 164.554 125.264 177.926 133.558 196.782C146.234 227.903 159.374 252.338 172.982 270.087C186.634 287.836 205.135 296.687 228.48 296.687C267.948 296.687 295.536 269.173 311.379 214.146C322.875 174.102 329.307 125.616 330.648 68.6894C330.773 63.3747 330.836 60.7174 329.81 58.6201C328.918 56.7948 327.419 55.2605 325.615 54.3253C323.543 53.2508 320.846 53.2508 315.451 53.2508H281.752C276.521 53.2508 273.906 53.2508 271.908 54.2331C270.126 55.1093 268.711 56.4579 267.751 58.1965C266.675 60.1454 266.547 62.8126 266.293 68.1471C265.272 89.5326 263.387 107.063 260.633 120.734Z"
                    fill="#ededed"
                />
            </svg>
        </div>
    )
}
